import React, { useRef, useEffect } from "react";
import { Utils } from "../../App/one-platform/shared";

export function useTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    if (Utils.isOnePlatform() || sessionStorage.getItem("platform") == "vop") {
      document.title = title;
    } else {
      document.title = "VP-" + title;
    }
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    []
  );
}
