import React, { useEffect } from "react";
import "../../styles/App.scss";
import { useGlobalStore } from "../../utils/global-store/vo/Vo-globalStore";
import * as Sentry from "@sentry/react";

//local
import WelcomePage from "../../components/vo/welcome-page/WelcomePage";
import AuthService from "../../services/vo/auth-service";

const VoWarrenty = ({ moduleData, moduleName }) => {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  globalVariabledispatch("SET_MODULE_DATA", { moduleData: moduleData });

  useEffect(() => {
    AuthService.matchCultureAndRedirectURL();
  }, []);

  return (
    <div>
      <WelcomePage></WelcomePage>
    </div>
  );
};

//export default VoWarrentyApp;
export default Sentry.withProfiler(VoWarrenty);
