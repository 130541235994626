import React, { useEffect, useState } from "react";
import RemainingDays from "../../../../utils/date-utils/remainigDays";
import defaultIcon from "../../../../images/brand/15.svg";
import { useTranslation } from "react-i18next";

//globle state
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";

//shared
import RemainingDaysCalculaions from "../../shared-components/remaining-days-calculations/RemainingDaysCalculations";
import { Utils } from "../../../../App/one-platform/shared";
const RemainingDateText = RemainingDaysCalculaions.RemainingDateText;

const CurrentCoverageInfo = () => {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const [coverageList, setCoverageList] = useState(null);
  const [vehicleIcon, setVehicleIcon] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    const vehicleDetails = JSON.parse(
      sessionStorage.getItem("currentCoverage")
    );
    setVehicleDetails(vehicleDetails);
    if (vehicleDetails?.ActiveInsurance?.vehicleTypeId) {
      const imageUrl = require(`../../../../images/brand/${vehicleDetails?.ActiveInsurance?.vehicleTypeId}.svg`);
      setVehicleIcon(imageUrl);
    } else if (vehicleDetails?.UserFlowStatus == "0") {
      let imageUrl;
      try {
        imageUrl = require(`../../../../images/brand/${vehicleDetails?.brandGroupId.toString()}.svg`);
      } catch (e) {
        imageUrl = require(`../../../../images/brand/default.svg`);
      }
      setVehicleIcon(imageUrl);
    }
    if (vehicleDetails?.UserFlowStatus != "0") {
      getSortedinsuranceList(
        vehicleDetails?.ActiveInsurance,
        vehicleDetails?.insurance
      );
    }
  }, []);

  const getSortedinsuranceList = (ActiveInsurance, insurances) => {
    var insuranceList = ActiveInsurance ? [ActiveInsurance] : [];
    insurances?.forEach((insurance) => {
      if (
        insurance.status === "Waiting" ||
        insurance.status === "Active" ||
        insurance.status === "Expired"
      )
        if (insurance.insuranceId != ActiveInsurance?.insuranceId) {
          insuranceList.push(insurance);
        }
    });
    SetRenewedStatus(insuranceList);
    setCoverageList(insuranceList);
    globalVariabledispatch("SET_CURRENT_COVERAGES", insuranceList);
  };

  const SetRenewedStatus = (insurances) => {
    var latestMBI = null;
    var extraProductList = [];
    insurances?.forEach((product) => {
      if (product.type == "MBI") {
        product.renewedProduct = false;
        if (!latestMBI) {
          latestMBI = product;
        } else if (new Date(product.startDate) < new Date(latestMBI.endDate)) {
          product.renewedProduct = true;
          latestMBI = product;
        }
      } else {
        product.renewedProduct = false;
        var isChecked = extraProductList.findIndex(
          (x) => x.insuranceType == product.insuranceType
        );
        if (isChecked == -1) {
          extraProductList.push(product);
        } else if (
          product.insuranceType == extraProductList[isChecked].insuranceType
        ) {
          if (
            new Date(product.startDate) <
            new Date(extraProductList[isChecked].endDate)
          ) {
            product.renewedProduct = true;
            extraProductList[isChecked] = product;
          }
        }
      }
    });
  };

  return (
    <div class="vehicle-summary-card mb-10">
      <div class="flex v-center mb-0">
        <div class="vehicle-icon-background mr-10">
          <img
            src={vehicleIcon}
            alt="logo"
            onError={(e) => {
              e.target.src = defaultIcon;
            }}
          ></img>
        </div>
        <div class="vehicle-info">
          <p>
            {Utils.isOnePlatform()
              ? t("renewal.product_page.coverage_info_description_text")
              : t("coverage_info_description_text")}
          </p>
          <h3>
            {vehicleDetails?.regNo}, {vehicleDetails?.modelName}
          </h3>
        </div>
      </div>
      {coverageList ? (
        <div>
          <div class="sub-title-typ-1 mb-10">
            {t("renewal.product_page.your_current_coverage")}
          </div>
          {coverageList?.map((insurance) => (
            <CurrentProductInfo
              ActiveInsurance={insurance}
              moduleData={globalVariable?.moduleData}
            ></CurrentProductInfo>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const CurrentProductInfo = ({ ActiveInsurance, moduleData }) => {
  return (
    <div class="flex space-between v-center coverage-info-wrap">
      <div class="coverage-info">
        {}
        <h5>{ActiveInsurance?.mainProductName}</h5>
      </div>
      {ActiveInsurance.status === "Waiting" ? (
        <div class="mbi-validity-default">
          {moduleData?.current_coverage_waiting_text}
        </div>
      ) : ActiveInsurance.status === "Expired" &&
        ActiveInsurance.renewedProduct == true ? (
        <div class="mbi-validity-renewed">
          {moduleData?.coverage_info_product_Expiredtext}
        </div>
      ) : ActiveInsurance.status === "Expired" ? (
        <div class="mbi-validity-dangerous">
          {moduleData.coverage_info_product_Expiredtext}
        </div>
      ) : (
        <RemainingDateText
          days={RemainingDays.GetDaysLeft(ActiveInsurance?.endDate)}
          renewedProduct={ActiveInsurance.renewedProduct}
          oneDayText={moduleData?.coverage_info_onedayLeft_Posttext}
          preText={moduleData?.coverage_info_daysLeft_Pretext}
          postText={moduleData?.coverage_info_daysLeft_Posttext}
          expiredText={moduleData?.coverage_info_product_Expiredtext}
        />
      )}
    </div>
  );
};

export default CurrentCoverageInfo;
