import React, { useState } from "react";
import ProductDetails from "../product-details-popup/product-details.component";
import ProductSelect from "../product-select-popup/product-select.component";
import ProductChangePopup from "../product-change-popup/ProductChangePopup";
import { useTranslation } from "react-i18next";

//services
import CartService from "../../../../../services/vo/add-to-cart-service";
import AuthService from "../../../../../services/vo/auth-service";
//image
import defaultIcon from "../../../../../images/icon_no_image";

//utils
import OfferDiscounts from "../../../../../utils/price-utils/offerDiscounts";
import DateUtils from "../../../../../utils/date-utils/DateFormater";
import PriceFormatter from "../../../../../utils/price-utils/PriceFormatter";

//globle state
import { useGlobalStore } from "../../../../../utils/global-store/vo/Vo-globalStore";
import ConfigData from "../../../../../services/config.json";

const ProductCard = (props) => {
  const {
    productData,
    type,
    isSpecial,
    selectedMBI,
    selectedExtras,
    OnDetailsClick,
    OnSelectClick,
    onProductRemove,
    selectPopupVisible,
    changePopupVisible,
    detailsPopupVisible,
    productInfo,
    OnCloseClick,
    OnSelectPopupComplete,
  } = props;
  const [choosenProduct, setChoosenProduct] = useState(null);
  const [globalVariable, globalVariabledispatch] = useGlobalStore(true);
  const imageUrl =
    ConfigData.AwisAPI.BaseAddress + `${productData?.offerBgImgURL}`;

  const { t } = useTranslation();

  const IsValidForDiscount = (offer) => {
    if (isSpecial != true && offer?.type === "Extra" && selectedMBI) {
      const discountPercentage = offer.extraReducedPrice?.find(
        (x) => x.mbiInsuranceTypeId == selectedMBI.insuranceTypeId
      );
      if (
        discountPercentage?.reducedPercentage &&
        discountPercentage?.reducedPercentage > 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isProductSelected = (product) => {
    product.isSpecial = isSpecial;
    return CartService.CheckItemAdded(product);
  };

  const HandleSelect = (offer) => {
    if (IsValidForDiscount(offer)) {
      const newOffer = { ...offer };
      newOffer.yearlyPaymentOriginal = offer.yearlyPayment;
      newOffer.monthlyPaymentOriginal = offer.monthlyPayment;
      newOffer.yearlyPayment = parseFloat(
        OfferDiscounts.getDiscountYearlyPrice(offer, selectedMBI)
      );
      newOffer.monthlyPayment = parseFloat(
        OfferDiscounts.getDiscountMonthlyPrice(offer, selectedMBI)
      );
      newOffer.discountedPrice = true;
      OnSelectClick(newOffer, isSpecial);
    } else {
      offer.discountedPrice = false;
      OnSelectClick(offer, isSpecial);
    }
  };

  const GetPricePeriod = (offer, selectedExtra) => {
    if (selectedMBI?.id == offer?.id) {
      if (selectedMBI?.selectedperiod) {
        return selectedMBI?.selectedperiod;
      } else {
        return { price: selectedMBI.yearlyPayment, period: 12 };
      }
    } else if (selectedExtra?.id == offer?.id) {
      if (selectedExtra?.selectedperiod) {
        return selectedExtra?.selectedperiod;
      } else {
        return { price: selectedExtra.yearlyPayment, period: 12 };
      }
    } else {
      return { price: offer.yearlyPayment, period: 12 };
    }
  };

  const PriceText = ({ price, month }) => {
    return (
      <div class="flex price">
        <span
          class={`yearly-price notranslate ${
            month === 1 ? "large-price" : "small-price"
          }`}
        >
          {PriceFormatter.getCurrencyFormat(t("language")).format(price)}{" "}
          {AuthService.getCurrencyCode(t("language"))}
          {month == 1 ? (
            <span class="month mr-5">/ {t("product_month_text")}</span>
          ) : (
            <span class="month mr-5">/ {t("product_months_text")}</span>
          )}
        </span>
      </div>
    );
  };

  const GetSelectedProduct = (type, offerId) => {
    if (type === "Extra") {
      const product = selectedExtras.find((element) => element.id == offerId);
      return product;
    } else {
      const product = selectedMBI;
      return product;
    }
  };

  const SelectedProductText = (offer) => {
    if (
      (offer?.type === "MBI" && selectedMBI) ||
      (offer?.type === "Extra" && selectedExtras?.length > 0)
    ) {
      const product = GetSelectedProduct(offer?.type, offer?.id);
      if (product) {
        const date = product?.startDate;
        const response = t("product_card_selected_text");
        var period = GetPricePeriod(offer, product).period;
        if (period > 1) {
          period = period;
        } else {
          period = period;
        }
        const data = {
          period: period,
          formatedDate: DateUtils.FormatDate(new Date(date)),
        };
        const replaceVariable = (match, inner) => {
          return data[inner] || "";
        };
        const replaced = response.replace(/\$\{(\w+)\}/gi, replaceVariable);
        return <span class="yearly-price">{replaced}</span>;
      }
    }
  };

  const chooseProduct = (offer) => {
    setChoosenProduct(offer);
  };

  return (
    <div class="products-panel-cards wrap">
      {productData?.map((offer) => (
        <div
          onClick={() => {
            chooseProduct(offer);
          }}
          key={offer.id}
          class={`mbi-card ${
            isProductSelected(offer)
              ? "mbi-card-ext-p border-selected"
              : choosenProduct?.id === offer.id
              ? "mbi-card-ext-p border-choosen"
              : "mbi-card-ext"
          }`}
        >
          <div class="icon-header-panel">
            {" "}
            {offer?.offerBgImgURL != null ? (
              <div class="mbi-icon gold">
                <img
                  src={offer.offerBgImgURL}
                  alt="mbi-image1"
                  onError={(e) => {
                    e.target.src = defaultIcon();
                  }}
                ></img>
              </div>
            ) : (
              <div class="mbi-icon gold">
                <img src={defaultIcon()} />
              </div>
            )}
          </div>
          <div class="card-body p-10 card-parent0">
            <div class="mbi-title">{offer.pName}</div>
            {isProductSelected(offer) ? (
              <div className="price">
                <div class="flex price">
                  <span class="yearly-price">{SelectedProductText(offer)}</span>
                </div>
                <div class="flex"></div>
              </div>
            ) : (
              <div className="price">
                {IsValidForDiscount(offer) ? (
                  <div>
                    <div class="flex">
                      <span>
                        <del>
                          <PriceText
                            price={offer.monthlyPayment}
                            month={1}
                          ></PriceText>
                        </del>
                      </span>
                      <span>
                        <del>
                          <PriceText
                            price={GetPricePeriod(offer).price}
                            month={GetPricePeriod(offer).period}
                          ></PriceText>
                        </del>
                      </span>
                    </div>
                    <div class="flex">
                      <span className="new-price">
                        <PriceText
                          price={OfferDiscounts.getDiscountMonthlyPrice(
                            offer,
                            selectedMBI
                          )}
                          month={1}
                        ></PriceText>
                      </span>
                      <span className="new-price">
                        <PriceText
                          price={OfferDiscounts.getDiscountYearlyPrice(
                            offer,
                            selectedMBI
                          )}
                          month={GetPricePeriod(offer).period}
                        ></PriceText>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div class="flex">
                      <span>
                        <PriceText
                          price={offer.monthlyPayment}
                          month={1}
                        ></PriceText>
                      </span>
                      <span>
                        <PriceText
                          price={GetPricePeriod(offer).price}
                          month={GetPricePeriod(offer).period}
                        ></PriceText>
                      </span>
                    </div>
                    {/* <div class="flex mb-20 mt-3"></div> */}
                  </div>
                )}
              </div>
            )}
            <div class="flex mbi-btn-wrapper mt-10">
              <a
                class="btn-secondary"
                onClick={() => OnDetailsClick(offer, isSpecial)}
              >
                {t("product_details_button")}
              </a>
              {isProductSelected(offer) ? (
                <a
                  class="btn-primary remove-mbi remove-btn-ext"
                  onClick={() => onProductRemove(offer)}
                >
                  {t("product_delete_button")}
                </a>
              ) : (
                <a class="btn-primary" onClick={() => HandleSelect(offer)}>
                  {t("product_select_button")}
                </a>
              )}
            </div>

            <div class="!accordion-body acc-body">
              {selectPopupVisible && productInfo.id === offer.id ? (
                <ProductSelect
                  onCloseClick={OnCloseClick}
                  productInfo={productInfo}
                  OnSelectPopupComplete={OnSelectPopupComplete}
                  selectedMBI={selectedMBI}
                ></ProductSelect>
              ) : (
                ""
              )}
              {detailsPopupVisible && productInfo.id === offer.id ? (
                <ProductDetails
                  onCloseClick={OnDetailsClick}
                  productInfo={productInfo}
                  OnSelectClick={OnSelectClick}
                  onProductRemove={onProductRemove}
                  selectedMBI={selectedMBI}
                ></ProductDetails>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductCard;
