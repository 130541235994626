import React, { useEffect }  from 'react';
import '../../styles/App.scss';
import { useGlobalStore } from '../../utils/global-store/vo/Vo-globalStore';
import * as Sentry from "@sentry/react";

//local 
import ProfilePage from '../../components/vo/profile-page/ProfilePage';
import AuthService from '../../services/vo/auth-service';

const VoProfileApp = ({ moduleData,moduleName }) => {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  globalVariabledispatch('SET_MODULE_DATA', { moduleData: moduleData });

  useEffect(() => {
    AuthService.matchCultureAndRedirectURL();
  }, []);
  
  return (
    <div className='edt-profile'>
         <ProfilePage></ProfilePage>
    </div>
  );
}

//export default VoProfileApp;
export default Sentry.withProfiler(VoProfileApp);

