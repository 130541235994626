import { HttpResponse, HttpService } from "../../../core";

export class OfferLetterRenewalService {
  private readonly baseUrl: string = process.env.REACT_APP_BASE_URL!;
  private httpService: HttpService;
  constructor() {
    this.httpService = new HttpService(this.baseUrl);
  }
  async GetLoginDetails<T>(offerKey: any): Promise<T> {
    return await this.httpService.get<T>(
      `Offer/Offerlogin?offerkey=${offerKey}`
    );
  }
}
