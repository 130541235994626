import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { IState } from "../../../../../redux/reducers";
import { OfferLetterRenewalAction } from "../../../../../redux/actions/offer-letter.action";
import { IOfferLetter } from "./offer-letter-renewal.interface";
import { Utils } from "../../../shared";

type T = {
  offerLetterLoginDetails?: IOfferLetter;
  offerLetterRenewal?: (data: any) => Promise<IOfferLetter>;
};

const OfferLetterRenewalComponent = ({
  offerLetterLoginDetails,
  offerLetterRenewal,
}: T): JSX.Element => {
  const [isOfferLetterFound, setIsOfferLetterFound] = useState<boolean>(true);
  const [isRenewded, setIsRenewded] = useState<boolean>(true);

  const { t } = useTranslation();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const offerkey = queryParams.get("offerkey");

    if (offerkey) {
      // Convert to string and separate the first two digits
      let firstTwoDigits = offerkey.toString().substring(0, 2);
      let lang = "sv-SE";
      let Vplang = "sv";

      offerLetterRenewal!(offerkey)
        .then((data) => {
          if (data.responsecode == "001") {
            setIsOfferLetterFound(false);
          } else if (data.responsecode == "002") {
            setIsRenewded(false);
          } else if (data.responsecode == "000") {
            if (data != undefined && data.body != undefined) {
              switch (
                firstTwoDigits[0].toString() + firstTwoDigits[1].toString()
              ) {
                case "06":
                  data.body.cultureCode = "sv-se";
                  lang = "sv-SE";
                  Vplang = "sv";
                  break;
                case "11":
                  data.body.cultureCode = "nn-no";
                  lang = "nn-NO";
                  Vplang = "no";
                  break;
                case "10":
                  data.body.cultureCode = "da-dk";
                  lang = "da-DK";
                  Vplang = "da";
                  break;
                case "09":
                  data.body.cultureCode = "fi-fi";
                  lang = "fi-FI";
                  Vplang = "fi";
                  break;
                default:
                  data.body.cultureCode = "sv-se";
                  lang = "sv-SE";
                  Vplang = "sv";
                  break;
              }
              sessionStorage.removeItem(
                "1986authdata19901985dataprocess_autoconcept_vo_access_token_info"
              );
              sessionStorage.setItem(
                "1986authdata19901985dataprocess_autoconcept_vo_access_token_info",
                JSON.stringify(data.body)
              );
              sessionStorage.setItem("isLogin", "1");
              sessionStorage.setItem("VpLang", Vplang);
              sessionStorage.setItem("platform", "vop");
              sessionStorage.setItem(
                "originUrl",
                btoa(`/vo-login?lang=${Utils.getCurrentLang()}`)
              );
              window.location.href =
                "/mypages/home?lang=" + lang + "&platform=vop";
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {}, [offerLetterLoginDetails]);

  return (
    <div>
      {!isOfferLetterFound && (
        <h3 style={{ marginTop: 15 }} className="error-msg">
          {t("renewal.offer_letter.offer_not_found")}
        </h3>
      )}
      {!isRenewded && (
        <h3 style={{ marginTop: 15 }} className="error-msg">
          {t("renewal.offer_letter.inusrnace_already_renewed")}
        </h3>
      )}
    </div>
  );
};

const mapStateToProps: any = (state: IState): any => {
  return { offerLetterLoginDetails: state.offerLetterLoginDetails };
};

const mapDispatchToProps: any = {
  offerLetterRenewal: OfferLetterRenewalAction.PostOfferLetterRenewal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferLetterRenewalComponent);
