import React from "react";
import RemainingDays from "../../../../utils/date-utils/remainigDays";
import { useTranslation } from "react-i18next";

const RemainingDateText = ({
  days,
  renewedProduct,
  postText,
  preText,
  oneDayText,
  expiredText,
}) => {
  const { t } = useTranslation();
  if (days == 1 && renewedProduct == true) {
    return (
      <div class="mbi-validity-renewed">
        {preText} {days} {oneDayText}{" "}
      </div>
    );
  } else if (days == 1) {
    return (
      <div class="mbi-validity-dangerous">
        {preText} {days} {oneDayText}{" "}
      </div>
    );
  } else if (days <= 0 && renewedProduct == true) {
    return <div class="mbi-validity-renewed">{expiredText}</div>;
  } else if (days <= 0) {
    return <div class="mbi-validity-dangerous">{expiredText}</div>;
  } else if (days > 0 && days <= 30 && renewedProduct == true) {
    return (
      <div class="mbi-validity-renewed">
        {preText} {days} {postText}{" "}
      </div>
    );
  } else if (days > 0 && days <= 30) {
    return (
      <div class="mbi-validity-dangerous">
        {preText} {days} {postText}{" "}
      </div>
    );
  } else {
    return (
      <div class="mbi-validity-default">
        {days} {t("renewal.insurance_overview.coverage_info_daysLeft_Posttext")}{" "}
      </div>
    );
  }
};

const getRemainingDaysCalculation = (data, moduleData) => {
  const { t } = useTranslation();
  if (data?.insuranceStatusId == "1") {
    // return <div class="mbi-validity-default">{moduleData.welcome_page_manage_coverage_pending} </div>
    return (
      <div class="mbi-validity-default">
        {moduleData?.welcome_page_vehicleinsurance_startingfrom}{" "}
        {data?.validFrom}{" "}
      </div>
    );
  } else if (data?.insuranceStatusId == "2" && data.validTo != null) {
    var days = RemainingDays.GetDaysLeft(data.validTo);
    return (
      <RemainingDateText
        days={days}
        renewedProduct={data.renewedProduct}
        oneDayText={
          moduleData.welcome_page_manage_coverage_remaining_one_day_post_text
        }
        preText={
          moduleData.welcome_page_manage_coverage_remaining_days_pre_text
        }
        postText={t(
          "renewal.insurance_overview.coverage_info_daysLeft_Posttext"
        )}
        expiredText={moduleData.welcome_page_manage_coverage_expired}
      />
    );
  } else {
    if (data.renewedProduct == true) {
      return (
        <div class="mbi-validity-renewed">
          {moduleData.welcome_page_manage_coverage_expired}
        </div>
      );
    } else {
      return (
        <div class="mbi-validity-dangerous">
          {moduleData.welcome_page_manage_coverage_expired}
        </div>
      );
    }
  }
};

export default { getRemainingDaysCalculation, RemainingDateText };
