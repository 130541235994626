import react, { useEffect } from "react";
import { useVisibility, Utils } from "../shared";

export const DHomeComponent: React.FC<any> = ({
  ...props
}: any): JSX.Element => {
  const { setIsVisible } = useVisibility();

  const getDHomeUrl: Function = (lang: string): string => {
    let url: string;
    switch (lang) {
      case "sv-SE":
        url = Utils.dHomeUrlSW;
        break;
      case "nn-NO":
        url = Utils.dHomeUrlNO;
        break;
      case "fi-FI":
        url = Utils.dHomeUrlFI;
        break;
      case "da-DK":
        url = Utils.dHomeUrlDK;
        break;
      default:
        url = Utils.dHomeUrlSW;
        break;
    }
    return url;
  };

  const lang: string = Utils.getQueryStringValue("lang");

  useEffect(() => {
    {
      /* SW 
  <script>
   const lnkWarrentyReg = document.querySelector('a[href="https://stg.dnn.autoconcept.se/insuranceregistrationnew"]');
   const lnkPriceList = document.querySelector('a[href="https://stg.dnn.autoconcept.se/d/pricelist"]');
   const lnkProdSell = document.querySelector('a[href="https://stg.dnn.autoconcept.se/d/products-to-sell"]');
   const lnkWarOverview = document.querySelector('a[href="https://stg.dnn.autoconcept.se/d/insurance-overview"]');
    lnkWarrentyReg.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/warranty-registration?lang=sv-SE'
            }, 'http://localhost:3000');
        });
   lnkPriceList.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/price-list?lang=sv-SE'
            }, 'http://localhost:3000');
        });
	lnkProdSell.addEventListener('click', function(event) {
			event.preventDefault();
		window.parent.postMessage({
				action: 'navigate',
				url: '/product-to-sell?lang=sv-SE'
			}, 'http://localhost:3000');
	});
	lnkWarOverview.addEventListener('click', function(event) {
				event.preventDefault();
		window.parent.postMessage({
				action: 'navigate',
				url: '/warranty-overview?lang=sv-SE'
			}, 'http://localhost:3000');
		});
    </script>

    NO
    <script>
   const lnkWarrentyReg = document.querySelector('a[href="https://stg.dnn.autoconcept.no/insuranceregistrationnew"]');
   const lnkPriceList = document.querySelector('a[href="https://stg.dnn.autoconcept.se/d/pricelist"]');
   const lnkProdSell = document.querySelector('a[href="https://stg.dnn.autoconcept.se/d/products-to-sell"]');
   const lnkWarOverview = document.querySelector('a[href="https://stg.dnn.autoconcept.se/d/insurance-overview"]');
    lnkWarrentyReg.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/warranty-registration?lang=nn-NO'
            }, 'http://localhost:3000');
        });
   lnkPriceList.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/price-list?lang=nn-NO'
            }, 'http://localhost:3000');
        });
	lnkProdSell.addEventListener('click', function(event) {
			event.preventDefault();
		window.parent.postMessage({
				action: 'navigate',
				url: '/product-to-sell?lang=nn-NO'
			}, 'http://localhost:3000');
	});
	lnkWarOverview.addEventListener('click', function(event) {
				event.preventDefault();
		window.parent.postMessage({
				action: 'navigate',
				url: '/warranty-overview?lang=nn-NO'
			}, 'http://localhost:3000');
		});
    </script>
  
     DK
    <script>
   const lnkWarrentyReg = document.querySelector('a[href="https://stg.dnn.autoconcept.dk/insuranceregistrationnew"]');
   const lnkPriceList = document.querySelector('a[href="https://stg.dnn.autoconcept.se/d/pricelist"]');
   const lnkProdSell = document.querySelector('a[href="https://stg.dnn.autoconcept.se/d/products-to-sell"]');
   const lnkWarOverview = document.querySelector('a[href="https://stg.dnn.autoconcept.se/d/insurance-overview"]');
    lnkWarrentyReg.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/warranty-registration?lang=da-DK'
            }, 'http://localhost:3000');
        });
   lnkPriceList.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/price-list?lang=da-DK'
            }, 'http://localhost:3000');
        });
	lnkProdSell.addEventListener('click', function(event) {
			event.preventDefault();
		window.parent.postMessage({
				action: 'navigate',
				url: '/product-to-sell?lang=da-DK'
			}, 'http://localhost:3000');
	});
	lnkWarOverview.addEventListener('click', function(event) {
				event.preventDefault();
		window.parent.postMessage({
				action: 'navigate',
				url: '/warranty-overview?lang=da-DK'
			}, 'http://localhost:3000');
		});
    </script>


     FI
    <script>
   const lnkWarrentyReg = document.querySelector('a[href="https://stg.dnn.autoconcept.fi/insuranceregistrationnew"]');
   const lnkPriceList = document.querySelector('a[href="https://stg.dnn.autoconcept.se/d/pricelist"]');
   const lnkProdSell = document.querySelector('a[href="https://stg.dnn.autoconcept.se/d/products-to-sell"]');
   const lnkWarOverview = document.querySelector('a[href="https://stg.dnn.autoconcept.se/d/insurance-overview"]');
    lnkWarrentyReg.addEventListener('click', function(event) {
                    event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/warranty-registration?lang=fi-FI'
            }, 'http://localhost:3000');
        });
   lnkPriceList.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/price-list?lang=fi-FI'
            }, 'http://localhost:3000');
        });
	lnkProdSell.addEventListener('click', function(event) {
			event.preventDefault();
		window.parent.postMessage({
				action: 'navigate',
				url: '/product-to-sell?lang=fi-FI'
			}, 'http://localhost:3000');
	});
	lnkWarOverview.addEventListener('click', function(event) {
				event.preventDefault();
		window.parent.postMessage({
				action: 'navigate',
				url: '/warranty-overview?lang=fi-FI'
			}, 'http://localhost:3000');
		});
    </script>
      */
    }

    //setIsVisible(false);
    window.addEventListener("message", (event) => {
      if (event.data.action === "navigate") {
        console.log(event.data.url);
        window.location.href = event.data.url + "&platform=vop";
      }
    });
    return () => setIsVisible(true);
  }, [setIsVisible]);

  return (
    <>
      <iframe
        scrolling="no"
        style={{ width: "100%", height: "1334px" }}
        src={getDHomeUrl(lang)}
      ></iframe>
    </>
  );
};
