import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import defaultIcon from "../../../../../images/icon_no_image";
import "react-datepicker/dist/react-datepicker.css";
import { useGlobalStore } from "../../../../../utils/global-store/vo/Vo-globalStore";
import { useTranslation } from "react-i18next";
import AuthService from "../../../../../services/vo/auth-service";

//utills
import RemainingDays from "../../../../../utils/date-utils/remainigDays";
import PriceFormatter from "../../../../../utils/price-utils/PriceFormatter";
import OfferDiscounts from "../../../../../utils/price-utils/offerDiscounts";
import { GACustomEventTrack } from "../../../../../utils/ga-utils/ga-utils";

function ProductSelectComponent(props) {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(true);
  const { onCloseClick, productInfo, OnSelectPopupComplete, selectedMBI } =
    props;
  const [startDate, setStartDate] = useState(new Date());
  const [selectedperiodIndex, setSelectedPeriodIndex] = useState(null);
  const [selectedperiod, setSelectedPeriod] = useState();
  const [tooltipVisibled, setTooltipVisibled] = useState(false);
  const [defaultPeriodSelected, SetDefaultPeriodSelected] = useState(true);
  const imageUrl = productInfo.offerBgImgURL;
  const [isSelectPaneMinimized, setIsSelectPaneMinimized] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (
      globalVariable.selectedVehicle?.UserFlowStatus == 2 &&
      globalVariable.selectedVehicle?.ActiveKeyProtect
    ) {
      var currentKeyProtectEndDate = new Date(
        globalVariable.selectedVehicle?.ActiveKeyProtect.endDate
      );
      var CurrentKeyProtectDaysLeft = RemainingDays.GetDaysLeft(
        globalVariable.selectedVehicle?.ActiveKeyProtect.endDate
      );
      IdentifyStartDate(currentKeyProtectEndDate, CurrentKeyProtectDaysLeft);
    } else if (productInfo?.type != "MBI") {
      const currentCoverages = globalVariable.currentCoverages;
      var CurrentInsuranceProduct = currentCoverages?.find(
        (x) => x.insuranceType == productInfo?.insuranceType
      );
      var CurrentInsuranceProductEndDate = new Date(
        CurrentInsuranceProduct?.endDate
      );
      var CurrentInsuranceProductDaysLeft = RemainingDays.GetDaysLeft(
        CurrentInsuranceProduct?.endDate
      );
      IdentifyStartDate(
        CurrentInsuranceProductEndDate,
        CurrentInsuranceProductDaysLeft
      );
    } else if (globalVariable.selectedVehicle?.ActiveInsurance) {
      var currentMBIEndDate = new Date(
        globalVariable.selectedVehicle?.ActiveInsurance.endDate
      );
      var CurrentMBIDaysLeft = RemainingDays.GetDaysLeft(
        globalVariable.selectedVehicle?.ActiveInsurance.endDate
      );
      IdentifyStartDate(currentMBIEndDate, CurrentMBIDaysLeft);
    }

    if (productInfo?.periodPriceList.length > 1) {
      setSelectedPeriod({ period: 0, price: 0 });
    } else {
      SetDefaultPeriodSelected(false);
      setSelectedPeriodIndex(0);
      setSelectedPeriod(productInfo?.periodPriceList[0]);
    }
  }, []);

  const IsValidForDiscount = (offer) => {
    if (offer.isSpecial != true && offer?.type === "Extra" && selectedMBI) {
      const discountPercentage = offer.extraReducedPrice?.find(
        (x) => x.mbiInsuranceTypeId == selectedMBI.insuranceTypeId
      );
      if (
        discountPercentage?.reducedPercentage &&
        discountPercentage?.reducedPercentage > 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const IdentifyStartDate = (currentEndDate, CurrentDaysLeft) => {
    if (CurrentDaysLeft <= 0) {
      var startDate = new Date(new Date().setHours(0, 0, 0, 0));
      setStartDate(startDate);
    } else {
      currentEndDate.setDate(currentEndDate.getDate() + 1);
      var startDate = new Date(currentEndDate.setHours(0, 0, 0, 0));
      setStartDate(startDate);
    }
  };

  const HandleChange = (e) => {
    const index = e.target.value;
    setSelectedPeriodIndex(index);
    setSelectedPeriod(productInfo?.periodPriceList[index]);
  };

  const onInfoClick = () => {
    setTooltipVisibled(!tooltipVisibled);
  };

  const onSelectedClick = () => {
    productInfo.startDate = startDate.toDateString();
    productInfo.selectedperiod = { ...selectedperiod };
    if (IsValidForDiscount(productInfo)) {
      productInfo.selectedperiodOriginal = { ...selectedperiod };
      productInfo.selectedperiod.price = OfferDiscounts.getDiscountPrice(
        productInfo.selectedperiod.price,
        productInfo,
        selectedMBI
      );
    }

    OnSelectPopupComplete(productInfo);
    GACustomEventTrack("click_buy_button", "Click Buy Button");
  };

  const PriceText = ({ price, selectedPeriod, monthlyPrice }) => {
    return (
      <div class="flex">
        <div class="flex price mb-20">
          <span class="yearly-price">
            {PriceFormatter.getCurrencyFormat(
              globalVariable?.moduleData.language
            ).format(price)}{" "}
            {AuthService.getCurrencyCode(t("language"))}
          </span>
          <span class="month">
            / {selectedPeriod?.period}{" "}
            {selectedPeriod?.period > 1
              ? globalVariable.moduleData?.product_months_text
              : globalVariable.moduleData?.product_month_text}
          </span>
        </div>
        <div class="flex price">
          <span class="monthly-price">
            {PriceFormatter.getCurrencyFormat(
              globalVariable?.moduleData.language
            ).format(monthlyPrice)}{" "}
            {AuthService.getCurrencyCode(t("language"))}
          </span>
          <span class="month">
            / {globalVariable.moduleData?.product_month_text}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div id="popup1" class="overlay overlay-ext product-popup detail-pane">
      <div class="popup popup-ext">
        <a class="close close-pop-ext" onClick={() => onCloseClick()}>
          &times;
        </a>
        <div
          class={`content ${
            isSelectPaneMinimized ? "low-height" : "content-prod-ext"
          }`}
        >
          <div class="popup-wrap p-20 pop-wrap-ext">
            <div className="flex ">
              <div class="width-100">
                <div class="flex column mb-3 p-relative f-left">
                  <label class="input-label">
                    {t("product_select_popup_startdate")}:
                  </label>
                  {productInfo?.isSpecial ? (
                    <span
                      className="info-icon info-icon-ext"
                      onClick={onInfoClick}
                    ></span>
                  ) : (
                    ""
                  )}
                  <DatePicker
                    inputProps={{ readOnly: true }}
                    popperModifiers={{
                      flip: {
                        behavior: ["bottom"],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                    class="input-style-2"
                    popperPlacement="bottom-start"
                    dateFormat="yyyy-MM-dd"
                    onFocus={(e) => {
                      setIsSelectPaneMinimized(false);
                      return (e.target.readOnly = true);
                    }}
                    onBlur={() => {
                      setIsSelectPaneMinimized(true);
                    }}
                    minDate={new Date().setHours(0, 0, 0, 0)}
                    disabled={productInfo?.isSpecial}
                    selected={startDate}
                    onChange={(date) => {
                      setIsSelectPaneMinimized(true);
                      setStartDate(date);
                    }}
                  />
                  {tooltipVisibled ? (
                    <div className="infomation-popup">
                      {t("renewal.product_page.product_select_popup_tooltip")}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div class="flex column mb-3 ml-3 f-left">
                  <label class="input-label">
                    {t("product_select_popup_selectperiod")}:
                  </label>
                  <select value={selectedperiodIndex} onChange={HandleChange}>
                    {defaultPeriodSelected ? (
                      <option value="" disabled selected>
                        {t("product_select_popup_deafult_selectperiod")}
                      </option>
                    ) : (
                      ""
                    )}
                    {productInfo?.periodPriceList?.map(
                      (pricePeriods, index) => (
                        <option key={pricePeriods.period} value={index}>
                          {pricePeriods.period}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div class="flex column mb-3 ml-3 f-left">
                  <button
                    class={
                      selectedperiodIndex != null
                        ? "btn-primary btn-buy-nw"
                        : "btn-disable btn-buy-nw"
                    }
                    disabled={selectedperiodIndex == null}
                    onClick={() => onSelectedClick()}
                  >
                    {/* {productInfo.type === "MBI" 
                      ? t("product_select_popup_select")
                      : globalVariable?.moduleData
                          ?.product_select_popup_addtocard}*/}
                          {t("product_select_popup_select")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductSelectComponent;
