import { useEffect, useState } from "react";
import { useGlobalStore } from "../../../utils/global-store/vo/Vo-globalStore";
import { useTranslation } from "react-i18next";
import i18n from "../../../services/i18n";
//css
import "./ThankYouPage.scss";
import Loading from "../../vo/shared-components/loading/Loading";
import insuranceService from "../../../services/vo/insurance-service";
import AuthService from "../../../services/vo/auth-service";
import userService from "../../../services/vo/user-service";
//service
import CartService from "../../../services/vo/add-to-cart-service";

//Utils
import { useTitle } from "../../../utils/title-utils/TabTitle";
import {
  GACustomPageView,
  GAPurchaseCustomEvent,
} from "../../../utils/ga-utils/ga-utils";
import { FBCustomEvent } from "../../../utils/fb-utils/fb-utils";
import { Utils } from "../../../App/one-platform/shared";

function ThankYouPage(props) {
  const [globalVariable] = useGlobalStore(false);
  const [loading, SetLoading] = useState(false);
  const [isNewCard, SetNewCard] = useState(false);
  const [orderInformation, SetOrderFormation] = useState([]);
  const { t } = useTranslation();
  const [loginPopup, setLoginPopup] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(async () => {
    const params = new URLSearchParams(window.location.search);
    SetLoading(true);

    var sessionLanguage = sessionStorage.getItem("VpLang");
    i18n.changeLanguage(sessionLanguage);

    if (sessionStorage.getItem("action") == "card-update") {
      SetNewCard(true);
      SetLoading(false);
    }

    let vehicleData = JSON.parse(sessionStorage.getItem("currentCoverage"));
    let originUrl = atob(sessionStorage.getItem("originUrl"));
    if (vehicleData) {
      FBCustomEvent(originUrl.split("/").pop() + " Payment Success Page", {
        vehicleNo: vehicleData.regNo,
        source: originUrl,
        title: t("thankyou_page_tab_title"),
        page: "Payment Success Page",
        campaign: originUrl.split("/").pop(),
      });
    }
    if (!AuthService.isAuthorized()) {
      // window.location.replace(globalVariable.moduleData.login_page_url);
    } else {
      sessionStorage.setItem("isLogin", "1");
      setIsLogin(true);
      if (sessionStorage.getItem("isNewUser") === "1") {
        console.log("Send otp");
        await sendOtp();
        sessionStorage.setItem("isNewUser", "0");
      }
      await getDibsOrderId();
      SetLoading(false);
    }

    if (JSON.parse(sessionStorage.getItem("vo_product")) != null) {
      CartService.removeAllFromCart();
    }

    sessionStorage.removeItem("vo-details");
    GACustomPageView("payment_success", "Payment Success");
    if (params.get("origin") != "null") {
      GACustomPageView(
        "campaign",
        "Thank you page - " + atob(params.get("origin")).split("/").pop()
      );
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem(
      "vo_product",
      JSON.stringify(
        JSON.parse(sessionStorage.getItem("autoconcept_vo_products"))
      )
    );

    var sessionLanguage = sessionStorage.getItem("VpLang");
    let currency = "SEK";

    switch (sessionLanguage) {
      case "sv":
        currency = "SEK";
        break;
      case "no":
        currency = "NOK";
        break;
      case "da":
        currency = "DKK";
        break;
      case "fi":
        currency = "EUR";
        break;
    }

    if (orderInformation.length > 0) {
      GAPurchaseCustomEvent(
        orderInformation[0].Amount,
        0,
        currency,
        orderInformation[0].ProductName
      );
    } else {
      const productData = JSON.parse(sessionStorage.getItem("vo_product"));
      if (productData != null) {
        GAPurchaseCustomEvent(
          productData[0].yearlyPayment,
          0,
          "SEK",
          productData[0].pName
        );
      }
    }
  }, [orderInformation]);

  useEffect(async () => {
    if (isLogin) {
      if (AuthService.isLogin()) {
      }
    }
    setIsLogin(false);
  }, [isLogin]);

  window.onpopstate = function () {
    window.history.forward();
  };

  useTitle(t("thankyou_page_tab_title"));

  const sendOtp = async (id, type) => {
    var customerData = AuthService.getTokenInfo();
    var orderData = JSON.parse(sessionStorage.getItem("DibsData"));
    try {
      if (orderData != null) {
        await userService.SendOtp(
          customerData.userName,
          "Vendor",
          orderData.DibsOrderId,
          "c"
        );
      } else {
        await userService.SendOtp(
          customerData.userName,
          "Vendor",
          sessionStorage.getItem("invoiceIncuranceId"),
          "i"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDibsOrderId = async () => {
    var orderData = JSON.parse(sessionStorage.getItem("DibsData"));
    if (orderData != null) {
      if (
        orderData?.DibsRequestType == "RENEWAL" ||
        orderData?.DibsRequestType == "NEWPAYMENT"
      ) {
        if (orderData?.DibsOrderId != null) {
          SetLoading(true);
          const response = await insuranceService.GetInsuranceDetailsByOrderId(
            orderData.DibsOrderId
          );
          console.log(response);
          SetOrderFormation(response.data);
          SetLoading(false);
        }
      } else if (orderData?.DibsRequestType == "NEWCARD") {
        SetNewCard(true);
      }
    }
  };

  const getCurrencyFormat = () => {
    return new Intl.NumberFormat(AuthService.getCultureCode(), {
      minimumFractionDigits: 0,
    });
  };

  const onHomeClick = (e) => {
    const originUrl = sessionStorage.getItem("originUrl");
    const lang = Utils.getUserLang(sessionStorage.getItem("VpLang"));
    let pageLang = "sv-SE";
    const platform = sessionStorage.getItem("platform");

    if (platform == "vop") {
      window.location.assign(`/mypages/home?lang=${lang}&platform=vop`);
    } else {
      if (originUrl !== null) {
        window.location.assign(atob(originUrl));
      } else {
        window.location.assign("/insurance-overview?lang=" + lang);
      }
    }
  };

  const onCloseOpenLogin = () => {
    setLoginPopup(false);
  };

  const handleActivateAcc = () => {
    setLoginPopup(true);
  };

  return (
    <div class="page-main-wrapper">
      {loading ? <Loading></Loading> : ""}
      <section class="thankyou-wrapper mt-10">
        <div class="confirm-icon-background mb-10">
          <div class="confrim-icon-accepted"></div>
        </div>
        {isNewCard ? (
          <div class="content-wrap">
            <h1 class="mb-5">
              {t("renewal.thank_you_page.thankyou_message_updatedcard")}
            </h1>
            <p class="mb-5">
              {t("renewal.thank_you_page.thankyou_message_updatedcard_detail")}
            </p>

            <div className="buttons-wrapper mt-20 mb-20 flex">
              <a
                href="javascript: void(0)"
                class="btn-primary"
                onClick={(e) => onHomeClick(e)}
              >
                {t("thankyou_message_backtohome")}
              </a>
            </div>
          </div>
        ) : (
          <div class="content-wrap">
            <h1 class="mb-5">{t("thankyou_message_success")}</h1>
            <div
              className="mb-10"
              style={{ textAlign: "center", color: "#999" }}
              dangerouslySetInnerHTML={{
                __html:
                  sessionStorage.getItem("platform") == "vop"
                    ? t("renewal.thank_you_page.thankyou_message_detail")
                    : t("thankyou_message_detail"),
              }}
            ></div>

            <div className="txt-center mt-10 contact-info">
              <div
                className="display-none"
                dangerouslySetInnerHTML={{
                  __html: t("activate_account_message"),
                }}
              ></div>
            </div>

            <div className="order-info mb-15">
              {orderInformation?.map((element) => (
                <div>
                  <div className="mt-10"></div>
                  <div className="cart-card">
                    <div className="flex v-center">
                      <div className="order-info-th">
                        {t("productname")}
                        <span className="orderNo-inner-ml">:</span>
                      </div>
                      <div className="order-info-td">
                        {element?.ProductName}
                      </div>
                    </div>
                    <div className="flex v-center">
                      <div className="order-info-th">
                        {t("orderNumber")}
                        <span className="orderNo-inner-ml">:</span>
                      </div>
                      <div className="order-info-td">{element.OrderId}</div>
                    </div>
                    <div className="flex v-center">
                      <div className="order-info-th">
                        {t("price_text")}
                        <span className="orderNo-inner-ml">:</span>
                      </div>
                      <div className="order-info-td color-primary">
                        {getCurrencyFormat().format(element?.Amount)}{" "}
                        {AuthService.getCurrencyCode(t("language"))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {sessionStorage.getItem("platform") != "vop" && (
              <div className="txt-center mt-5 contact-info">
                <p>
                  {t("thankyoupage_text")}
                  <br />
                </p>
              </div>
            )}

            <div className="order-info display-none">
              <div className="cart-card">
                <div className="flex v-center">
                  <div className="order-info-th">
                    {t("tele_no_th")}
                    <span className="orderNo-inner-ml">:</span>
                  </div>
                  <div className="order-info-td">0300 - 56 38 10</div>
                </div>
                <div className="flex v-center">
                  <div className="order-info-th">
                    {t("email_th")}
                    <span className="orderNo-inner-ml">:</span>
                  </div>
                  <div className="order-info-td">info@autoconcept.se</div>
                </div>
              </div>
            </div>

            <div className="txt-center mt-10 contact-info">
              <p className="mb-10">
                {sessionStorage.getItem("platform") == "vop"
                  ? t("renewal.thank_you_page.sincerely_text")
                  : t("sincerely_text")}
              </p>
            </div>

            <div className="txt-center mt-20 contact-info">
              <p className="mb-5 mt-5">AutoConcept Insurance AB</p>
            </div>

            <div className="buttons-wrapper mt-20 mb-40 flex">
              <a
                href="javascript: void(0)"
                class="btn-primary"
                onClick={(e) => onHomeClick(e)}
              >
                {t("thankyou_message_backtohome")}
              </a>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

export default ThankYouPage;
