import { initialState } from "./index";
import { ActionTypes } from "../actions";
import { IOfferLetter } from "../../App/one-platform/vo/renewal/offer-letter-renewal/offer-letter-renewal.interface";

const offerLetterReducer = (
  state: IOfferLetter = initialState.offerLetterLoginDetails,
  action: any
): IOfferLetter => {
  switch (action.type) {
    case ActionTypes.GET_OFFER_LETTER_LOGIN_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

export default offerLetterReducer;
