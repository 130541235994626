import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import ProductPdfPopup from "../../shared-components/product-pdf-popup/ProductPdfPopup";
import RemainingDaysCalculations from "../../shared-components/remaining-days-calculations/RemainingDaysCalculations";

import WarrantyService from "../../../../services/vo/warranty-service";
import AuthService from "../../../../services/vo/auth-service";
import InsuranceService from "../../../../services/vo/insurance-service";
import BluePdf from "../../../../images/pdf_icon_blue.svg";
import RedPdf from "../../../../images/pdf_icon_green.svg";
// import defaultIcon from '../../../../images/icon_no_image.svg';
import defaultIcon from "../../../../images/icon_no_image";
import Loading from "../../shared-components/loading/Loading";
import ConfigData from "../../../../services/config.json";
import dibsEng from "../../../../images/dibs/DIBS_shop_vertical_EN_10.png";

//globle states
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";

function ProductDetailsPopup(props) {
  const { onCloseClick, productInfo, OnManageCoverageClick } = props;
  const [pdfPopupVisible, setPdfPopupVisible] = useState(false);
  const [openpdfUrl, setPdfUrl] = useState(null);
  const [loading, SetLoading] = useState(false);
  const [globalVariable] = useGlobalStore(false);
  const [displayRenewButton, setDisplayRenewButton] = useState(false);

  const imageUrl =
    ConfigData.AwisAPI.BaseAddress + `${productInfo.productImagePathSM}`;
  const dibsPaymentnets = require("../../../../images/dibs/" +
    AuthService.getPaymentUpdateImage());

  const { t } = useTranslation();

  const pdfNewTabStyle =
    "body{display:flex;justify-content:center}" +
    ".loader { margin-top:15%;	border: 6px solid #f3f3f3;border-radius: 50%;border-top: 6px solid #03687c; width: 60px;height: 60px;-webkit-animation: spin 2s linear infinite;animation: spin 2s linear infinite;}" +
    "@-webkit-keyframes spin {0% { -webkit-transform: rotate(0deg); }100% { -webkit-transform: rotate(360deg); }}" +
    "@keyframes spin {0% { transform: rotate(0deg);}100% { transform: rotate(360deg);}}";

  useEffect(() => {
    /* if (productInfo?.isRenewed == "true") {
             setDisplayRenewButton(false);
         } else {
             if (productInfo?.insuranceTypeName == "KeyPlate" && productInfo?.offerLetterGen == "false") {
                 setDisplayRenewButton(false);
             }
             else if(productInfo?.insuranceTypeName == "Extra") {
                 setDisplayRenewButton(false);
             }
             else {
                 setDisplayRenewButton(true);
             }
         }
         */
    if (
      productInfo?.renewedProduct == false &&
      productInfo?.offerLetterGen == "true"
    ) {
      setDisplayRenewButton(true);
    }
  }, []);

  const onOpenTermsAndConditionsPdfClick = () => {
    if (!loading) {
      const newTab = window.open("");
      newTab.document.write(
        "<head><title>Loading..</title><style>" +
          pdfNewTabStyle +
          "</style></head>"
      );
      newTab.document.write('<body><div class="loader"></div></body>');
      LoadtermsPDF(newTab);
    }
  };

  async function LoadtermsPDF(newTab) {
    try {
      SetLoading(true);
      const response = await WarrantyService.GetTermsPDFURL(productInfo.id);
      if (response.data != null && response.data?.status) {
        //setPdfUrl(response.data?.pdfUrl);
        //setPdfPopupVisible(true);
        newTab.location.replace(response.data?.pdfUrl);

        SetLoading(false);
      }
      SetLoading(false);
    } catch (err) {
      console.log(err);
      newTab.document.body.innerHTML = "";
      newTab.document.write(
        "<div><h4>Something wrong please try again<h4></div>"
      );
      SetLoading(false);
    }
  }

  const onOpenComponentPdfClick = () => {
    if (!loading) {
      SetLoading(true);
      if (
        !(
          productInfo?.ipidDocumentURL == null ||
          productInfo?.ipidDocumentURL == ""
        )
      ) {
        setPdfUrl(productInfo.ipidDocumentURL);
        window.open(productInfo?.ipidDocumentURL, "_blank");
        SetLoading(false);
      } else {
        const newTab = window.open("");
        newTab.document.write(
          "<head><title>Loading..</title><style>" +
            pdfNewTabStyle +
            "</style></head>"
        );
        newTab.document.write('<body><div class="loader"></div></body>');
        LoadComponentPdf(newTab);
      }
    }
  };

  async function LoadComponentPdf(newTab) {
    try {
      const response = await WarrantyService.GetComponentsPDFURL(
        productInfo.id
      );
      if (response.data != null && response.data?.status) {
        // setPdfUrl(response.data?.pdfUrl);
        //setPdfPopupVisible(true);
        newTab.location.replace(response.data?.pdfUrl);
      }
      SetLoading(false);
    } catch (err) {
      console.log(err);
      newTab.document.body.innerHTML = "";
      newTab.document.write(
        "<div><h4>Something wrong please try again<h4></div>"
      );
      SetLoading(false);
    }
  }

  const OnDetailsClick = () => {
    if (!pdfPopupVisible) {
      setPdfPopupVisible(true);
    } else {
      setPdfPopupVisible(false);
    }
  };

  const onclickManage = () => {
    //status =2 - KeyProtect renewal flow
    /*if (productInfo?.insuranceTypeName == "KeyPlate") {
            window.location.assign(`${globalVariable.moduleData.product_page_url}?vehicle_no=${btoa(productInfo?.regNo)}&mileage=${btoa(0)}&insuranceId=${btoa(productInfo?.id)}&status=${btoa(2)}`);
            SetLoading(true);
        } else {*/
    onCloseClick();
    OnManageCoverageClick();
    //}
  };

  // const onclickCardChangeRequest = async (e) => {
  //     e.preventDefault();
  //     let renewObj = {
  //         langCode: globalVariable.moduleData.language,
  //         insuranceId: productInfo.id
  //     };
  //     SetLoading(true);
  //     const response = await InsuranceService.GetOrderNoForNewCardChangeRequest(renewObj);
  //     if (response.data != null) {
  //         SetLoading(true);
  //         sessionStorage.removeItem('DibsData');
  //         const dibsData = {
  //             'DibsOrderId': response.data.orderNo,
  //             'DibsRequestType': 'NEWCARD',
  //             'createdAt': new Date()
  //         }
  //         sessionStorage.setItem('DibsData', JSON.stringify(dibsData));
  //         await InsuranceService.GetChangeCreditCard(response.data.orderNo);
  //     } else {
  //         // window.location.replace(globalVariable.moduleData.payment_failed_url);
  //         // SetLoading(false);
  //         SetLoading(false);
  //     }
  // }

  const onclickCardChangeRequest = async (e) => {
    e.preventDefault();
    // let renewObj = {
    //     langCode: globalVariable.moduleData.language,
    //     insuranceId: productInfo.id
    // };
    SetLoading(true);
    const response = await InsuranceService.RenewCreditCard(productInfo.id);
    if (response.data != null) {
      SetLoading(false);
      // window.location = JSON.parse(response.data).hostedPaymentPageUrl;
      sessionStorage.setItem("action", "card-update");
      window.location = response.data;
    } else {
      // window.location.replace(globalVariable.moduleData.payment_failed_url);
      // SetLoading(false);
      SetLoading(false);
    }
  };

  return (
    <div id="popup1" class="overlay detail-popup">
      <div class="popup">
        {loading ? <Loading></Loading> : ""}
        <a class="close" onClick={() => onCloseClick()}>
          &times;
        </a>
        <div class="content">
          <div class="popup-header">
            {productInfo.productImagePathSM != null ? (
              <div class="icon-large">
                <img src={imageUrl} alt="banner-image"></img>
              </div>
            ) : (
              <div class="icon-gold">
                <img src={defaultIcon()} />
              </div>
            )}
          </div>
          <div class="popup-wrap p-20">
            <div class="popup-body mb-20">
              <div className="flex space-between h-center mob-block">
                <div class="mbi-title">{productInfo?.productName}</div>
                <div className="flex space-between v-center h-center mob-block">
                  {RemainingDaysCalculations.getRemainingDaysCalculation(
                    productInfo,
                    globalVariable.moduleData
                  )}
                </div>
              </div>

              {productInfo?.annual_payment != null &&
              productInfo?.monthly_payment != null ? (
                <div class="flex">
                  <div class="flex price mr-5">
                    <span class="yearly-price">
                      {productInfo?.annual_payment}{" "}
                      {AuthService.getCurrencyCode(t("language"))}
                    </span>
                    <span class="month">/ 12 Months</span>
                  </div>
                  <div class="flex price">
                    <span class="monthly-price">
                      {productInfo?.monthly_payment}{" "}
                      {AuthService.getCurrencyCode(t("language"))}
                    </span>
                    <span class="month">/ Month</span>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div class="documents">
                <span></span>
                <span></span>
              </div>
              <p class="mb-20">{productInfo?.productDescription} </p>
              <div className="term-policy-parent">
                <a
                  class="javascript: void(0)"
                  style={{ cursor: "pointer" }}
                  onClick={() => onOpenComponentPdfClick()}
                >
                  <img src={RedPdf} />{" "}
                  <span> {t("renewal.insurance_overview.policy_text")}</span>
                </a>
                <a
                  class="javascript: void(0)"
                  style={{ cursor: "pointer" }}
                  onClick={() => onOpenTermsAndConditionsPdfClick()}
                >
                  <img src={BluePdf} class="ml-10" />
                  <span> {t("renewal.insurance_overview.term_text")}</span>
                </a>
              </div>

              <div className="mt-10">
                <div
                  style={{ fontSize: 14 }}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "renewal.insurance_overview.product_detail_pop_block_help_text"
                    ),
                  }}
                />
              </div>
              <div className="pd-pop-btn-wrapper">
                <div className="update-card-info">
                  {productInfo.enableRenewCardButton == "true" ? (
                    <a
                      class="javascript: void(0)"
                      className="btn-primary mt-10"
                      onClick={(e) => onclickCardChangeRequest(e)}
                    >
                      {t("renewal.insurance_overview.update_card_information")}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
                <div className="manage-coverage">
                  {displayRenewButton ? (
                    <a
                      class="javascript: void(0)"
                      className="btn-primary"
                      onClick={() => onclickManage()}
                    >
                      {t("renewal.insurance_overview.buy_button")}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {productInfo.enableRenewCardButton == "true" ? (
                <div className="payment-img">
                  <div class="">
                    <img src={dibsPaymentnets} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {pdfPopupVisible && openpdfUrl != null ? (
        <ProductPdfPopup onCloseClick={OnDetailsClick} pdfurl={openpdfUrl} />
      ) : (
        ""
      )}
    </div>
  );
}

export default ProductDetailsPopup;
