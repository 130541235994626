import react, { useEffect } from "react";
import { useVisibility, Utils } from "../shared";

export const VOHomeComponent: React.FC<any> = ({
  ...props
}: any): JSX.Element => {
  const { setIsVisible } = useVisibility();

  const getVOHomeUrl: Function = (lang: string): string => {
    let url: string;
    switch (lang) {
      case "sv-SE":
        url = Utils.voHomeUrlSW;
        break;
      case "nn-NO":
        url = Utils.voHomeUrlNO;
        break;
      case "fi-FI":
        url = Utils.voHomeUrlFI;
        break;
      case "da-DK":
        url = Utils.voHomeUrlDK;
        break;
      default:
        url = Utils.voHomeUrlSW;
        break;
    }
    return url;
  };

  const lang: string = Utils.getQueryStringValue("lang");

  useEffect(() => {
    {
      /* 
  //publisher snippet to insert on HS Page
  SW-HSPage 
   <script>
   const lnkInsOverview = document.querySelector('a[href="/mypages/insurance-overview?hsLang=sv"]');
   const lnkMyProfile = document.querySelector('a[href="/mypages/my-profile?hsLang=sv"]');
    lnkInsOverview.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/insurance-overview?lang=sv-SE'
            }, 'http://localhost:3000');
        });
   lnkMyProfile.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',

                url: '/vo-profile?lang=sv-SE'

            }, 'http://localhost:3000');
        });
    </script>
    
     DK-HSPage
     <script>
   const lnkInsOverview = document.querySelector('a[href="/mypages/insurance-overview?hsLang=da"]');
   const lnkMyProfile = document.querySelector('a[href="/mypages/my-profile?hsLang=da"]');
    lnkInsOverview.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/insurance-overview?lang=da-DK'
            }, 'http://localhost:3000');
        });
   lnkMyProfile.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',

                url: '/vo-profile?lang=da-DK'

            }, 'http://localhost:3000');
        });
    </script>

    NO-HSPage
     <script>
   const lnkInsOverview = document.querySelector('a[href="/mypages/insurance-overview?hsLang=nb"]');
   const lnkMyProfile = document.querySelector('a[href="/mypages/my-profile?hsLang=nb"]');
    lnkInsOverview.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/insurance-overview?lang=nn-NO'
            }, 'http://localhost:3000');
        });
   lnkMyProfile.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',

                url: '/vo-profile?lang=nn-NO'

            }, 'http://localhost:3000');
        });
    </script>
    
    FI-HSPage
     <script>
   const lnkInsOverview = document.querySelector('a[href="/mypages/insurance-overview?hsLang=fi"]');
   const lnkMyProfile = document.querySelector('a[href="/mypages/my-profile?hsLang=fi"]');
    lnkInsOverview.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/insurance-overview?lang=fi-FI'
            }, 'http://localhost:3000');
        });
   lnkMyProfile.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',

                url: '/vo-profile?lang=fi-FI'

            }, 'http://localhost:3000');
        });
    </script>
    
    */
    }

    //setIsVisible(false);
    window.addEventListener("message", (event) => {
      if (event.data.action === "navigate") {
        console.log(event.data.url);
        window.location.href = event.data.url;
      }
    });
    return () => setIsVisible(true);
  }, [setIsVisible]);

  return (
    <>
      <iframe
        scrolling="no"
        style={{ width: "100%", height: "1334px" }}
        src={getVOHomeUrl(lang)}
      ></iframe>
    </>
  );
};
