import { ActionTypes } from "./action.types";
import { IAction } from "./action.interface";
import { IOfferLetter } from "../../App/one-platform/vo/renewal/offer-letter-renewal/offer-letter-renewal.interface";
import { OfferLetterRenewalService } from "../../App/one-platform/vo/renewal/offer-letter-renewal/offer-letter-renewal.service";

export class OfferLetterRenewalAction {
  static PostOfferLetterRenewalSuccess(
    offerLetterLoginDetails: IOfferLetter
  ): IAction<string, IOfferLetter> {
    return {
      type: ActionTypes.GET_OFFER_LETTER_LOGIN_DETAILS,
      payLoad: offerLetterLoginDetails,
    };
  }
  static PostOfferLetterRenewal<T extends IOfferLetter>(
    offerKey: any
  ): Function {
    return async (dispatch: any) => {
      return await new OfferLetterRenewalService()
        .GetLoginDetails<T>(offerKey)
        .then((offerLetterLoginDetails: T): T => {
          dispatch(
            OfferLetterRenewalAction.PostOfferLetterRenewalSuccess(
              offerLetterLoginDetails
            )
          );
          return offerLetterLoginDetails;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }
}
