import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PasswordChange from "../shared-components/password-change/PasswordChange";
import PasswordResetPage from "../password-reset-page/PasswordResetPage";
import Loading from "../shared-components/loading/Loading";

import AuthService from "../../../services/vo/auth-service";
import Consent from "./consent/consent";
//global states
import { useGlobalStore } from "../../../utils/global-store/vo/Vo-globalStore";
import { Utils } from "../../../App/one-platform/shared";
import { useTitle } from "../../../utils/title-utils/TabTitle";

const LoginPage = () => {
  const [globalVariable] = useGlobalStore(false);
  const [loading, SetLoading] = useState(false);
  const [isLogin, SetIsLogin] = useState(true);
  const [isFirstTimePasswordChanged, SetIsFirstTimePasswordChanged] =
    useState(false);
  const [isPolicyAccepted, SetIsPolicyAccepted] = useState(false);
  const [isPasswordReset, SetIsPasswordReset] = useState(false);
  const [tokenData, SetTokenData] = useState(null);
  const { handleSubmit, register, errors, setValue } = useForm();
  const [errorMessage, SetErrorMesssage] = useState(null);
  const [userNameToolTipVisible, SetUserNameToolTipVisible] = useState(false);
  const [passwordToolTipVisible, SetPasswordToolTipVisible] = useState(false);

  const userNameToolTipWrapper = useRef(null);
  const passwordToolTipWrapper = useRef(null);
  const history = useHistory();
  const { t } = useTranslation();

  useTitle(t("renewal.login_page.page_title"));

  const handleClickOutside = (event) => {
    if (
      userNameToolTipWrapper.current &&
      !userNameToolTipWrapper.current.contains(event.target)
    ) {
      SetUserNameToolTipVisible(false);
    }
    if (
      passwordToolTipWrapper.current &&
      !passwordToolTipWrapper.current.contains(event.target)
    ) {
      SetPasswordToolTipVisible(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("platform", "vop");
    const queryParams = new URLSearchParams(window.location.search);
    const platformPara = queryParams.get("platform");
    if (platformPara == null) {
      let newUrl = new URL(document.URL);

      // Add or update the query parameter (for example, 'newParam' with value '123')
      newUrl.searchParams.set("platform", "vop");

      // Use history.pushState to update the URL without triggering a page reload
      window.history.pushState({ path: newUrl.href }, "", newUrl.href);
    }
    document.addEventListener("click", handleClickOutside, false);
    CheckAuthFlow();
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const CheckAuthFlow = () => {
    SetLoading(true);
    const isAuthenticated = AuthService.isAuthorized();
    if (isAuthenticated) {
      const currentToken = AuthService.getTokenInfo();
      HandlePageRedirect(currentToken);
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
      SetLoading(false);
    }
  };

  const redirectSuccessLogin = () => {
    let callBkUrl = decodeURIComponent(Utils.getQueryStringValue("callBkUrl"));
    let curPath = window.location.pathname;
    let isDLogin = curPath.includes("/d-login");
    let lang = Utils.getCurrentLang();
    let defRedirectUrl = isDLogin
      ? `${Utils.dearlerLandUrl}?lang=${lang}`
      : `${Utils.voLandUrl}?platform=vop&lang=${lang}`;
    saveOriginUrl(isDLogin, lang);
    if (!Utils.isNullOrEmpty(callBkUrl)) {
      history.push(callBkUrl);
    } else {
      history.push(defRedirectUrl);
    }
  };

  const saveOriginUrl = (isDLogin, lang) => {
    if (isDLogin) {
      sessionStorage.setItem("originUrl", btoa(`/d-login?lang=${lang}`));
    } else {
      sessionStorage.setItem("originUrl", btoa(`/vo-login?lang=${lang}`));
    }
  };

  const HandlePageRedirect = (token) => {
    if (token?.cultureCode?.toLowerCase() !== t("language").toLowerCase()) {
      AuthService.signOut();
      SetLoading(false);
    } else if (
      token?.isFirstTimePasswordChanged == "True" &&
      token?.isPolicyAccepted == "True"
    ) {
      if (token?.isValidCustomerInfo == "False") {
        history.push(
          globalVariable.moduleData.profile_page_url +
            "?missed_data=" +
            btoa(true)
        );
      } else {
        redirectSuccessLogin();
      }
    } else {
      let isDLogin = window.location.pathname.includes("/d-login");
      if (isDLogin) {
        redirectSuccessLogin();
      } else {
        SetIsPolicyAccepted(token.isPolicyAccepted == "True");
        SetTokenData(token);
        SetIsFirstTimePasswordChanged(
          token.isFirstTimePasswordChanged == "True"
        );
        SetIsLogin(false);
        SetLoading(false);
      }
    }
  };

  const OnPasswordResetClicked = () => {
    SetErrorMesssage(null);
    SetIsLogin(false);
    SetIsPasswordReset(true);
  };

  const GoToLoginPage = (username) => {
    SetIsPasswordReset(false);
    SetIsLogin(true);
    setValue("username", username, { shouldValidate: true });
  };

  const onPolicyAccepted = () => {
    tokenData.isPolicyAccepted = "True";
    AuthService.storeTokenInfo(tokenData);
    SetIsPolicyAccepted(true);
    CheckAuthFlow();
  };

  const onPasswordChange = () => {
    tokenData.isFirstTimePasswordChanged = "True";
    AuthService.storeTokenInfo(tokenData);
    SetIsFirstTimePasswordChanged(true);
    CheckAuthFlow();
  };

  const onSubmit = async (values) => {
    let curPath = window.location.pathname;
    let isDLogin = curPath.includes("/d-login");
    let lang = Utils.getCurrentLang();
    SetErrorMesssage(null);
    try {
      SetLoading(true);
      const data = {
        username: values.username.trim(),
        password: values.password,
        grant_type: "password",
        client_id: "AutoconceptWeb",
        culture_code: lang,
        user_type: isDLogin ? 2 : 3,
      };
      const response = await AuthService.login(data);
      response.data.cultureCode = t("language");
      response.data.loginflow = "Main";
      await AuthService.storeTokenInfo(response.data);
      await sessionStorage.setItem("isLogin", "1");
      HandlePageRedirect(response.data);
    } catch (error) {
      SetLoading(false);
      if (error != null) {
        SetErrorMesssage(t("renewal.login_page.error_message"));
      }
    }
  };

  return (
    <section className="login-container mid-container">
      {loading ? (
        <Loading></Loading>
      ) : (
        <div>
          <div class="flex mob-block wrap">
            {isLogin ? (
              <div class="mt-30 width-50">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h5>{t("renewal.login_page.login_panal_title")}</h5>
                  <div class="flex column mb-30">
                    <label class="input-label mb-5">
                      {t("renewal.login_page.login_username")}
                      <span className="ToolTips">
                        <span
                          className="tooltip-icon"
                          ref={userNameToolTipWrapper}
                          onClick={() =>
                            SetUserNameToolTipVisible(!userNameToolTipVisible)
                          }
                        ></span>
                        {userNameToolTipVisible ? (
                          <span className="tooltip-box">
                            {t(
                              "renewal.login_page.login_username_tooltip_text"
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </label>
                    <input
                      class="input-style-2"
                      type="text"
                      name="username"
                      ref={register({
                        required: t("renewal.login_page.login_username_error"),
                      })}
                    />
                    <span className="error">
                      {" "}
                      {errors.username && errors.username.message}
                    </span>
                  </div>
                  <div class="flex column mb-30">
                    <label class="input-label mb-5">
                      {t("renewal.login_page.login_password")}
                      <span className="ToolTips">
                        <span
                          className="tooltip-icon"
                          ref={passwordToolTipWrapper}
                          onClick={() =>
                            SetPasswordToolTipVisible(!passwordToolTipVisible)
                          }
                        ></span>
                        {passwordToolTipVisible ? (
                          <span className="tooltip-box">
                            {t(
                              "renewal.login_page.login_password_tooltip_text"
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </label>
                    <input
                      class="input-style-2"
                      type="password"
                      name="password"
                      ref={register({
                        required: t("renewal.login_page.login_password_error"),
                      })}
                    />
                    <span className="error">
                      {" "}
                      {errors.password && errors.password.message}
                    </span>
                  </div>
                  {errorMessage != null ? (
                    <div class="flex column">
                      <span className="error"> {errorMessage}</span>
                    </div>
                  ) : (
                    ""
                  )}

                  <div class="flex mb-30 v-center space-between">
                    <div>
                      <a onClick={OnPasswordResetClicked}>
                        {t("renewal.login_page.forgot_password")}
                      </a>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      {t("renewal.login_page.sign_in_test")}
                    </button>
                  </div>
                </form>
              </div>
            ) : isPasswordReset ? (
              <PasswordResetPage
                GoToLoginPage={GoToLoginPage}
              ></PasswordResetPage>
            ) : !isPolicyAccepted ? (
              <Consent
                onPolicyAccepted={onPolicyAccepted}
                tokenData={tokenData}
              ></Consent>
            ) : !isFirstTimePasswordChanged ? (
              <PasswordChange
                onPasswordChange={onPasswordChange}
                tokenData={tokenData}
                isLoginFlow={true}
              ></PasswordChange>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default LoginPage;
